import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Profiles from './profile';  // Your Profiles component
import Home from './home';  // Your Home component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />        {/* Homepage */}
        <Route path="/profile" element={<Profiles />} />  {/* Profile page */}
        {/* Add other routes if necessary */}
      </Routes>
    </Router>
  );
}

export default App;
