import React, { useEffect, useState } from 'react';
import { db, analytics } from '../src/firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics'; // Import logEvent for tracking
import './App.css';
import logo from './logo.jpg';

const App = () => {
  const [posts, setPosts] = useState([]);
  const [movies, setMovies] = useState([]);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  // Log page view on load
  useEffect(() => {
    logEvent(analytics, 'page_view', { page_title: 'Home Page' });

    const fetchPosts = async () => {
      logEvent(analytics, 'fetch_posts_start');
      const postsCollection = collection(db, 'posts');
      const postsSnapshot = await getDocs(postsCollection);
      const postsData = postsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      postsData.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp.toMillis() : 0;
        const timestampB = b.timestamp ? b.timestamp.toMillis() : 0;
        return timestampB - timestampA;
      });

      setPosts(postsData);
      logEvent(analytics, 'fetch_posts_success', { count: postsData.length });
    };

    const fetchMovies = async () => {
      logEvent(analytics, 'fetch_movies_start');
      const moviesCollection = collection(db, 'movies');
      const moviesSnapshot = await getDocs(moviesCollection);
      const moviesData = moviesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMovies(moviesData);
      logEvent(analytics, 'fetch_movies_success', { count: moviesData.length });
    };

    fetchPosts();
    fetchMovies();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      try {
        logEvent(analytics, 'message_submit_attempt');
        await addDoc(collection(db, 'messages'), {
          content: message,
          timestamp: new Date(),
        });
        alert('Message submitted successfully!');
        setMessage('');
        logEvent(analytics, 'message_submit_success', { content_length: message.length });
      } catch (error) {
        console.error('Error submitting message:', error);
        alert('Error submitting message. Please try again.');
        logEvent(analytics, 'message_submit_failure', { error_message: error.message });
      }
    } else {
      alert('Please enter a message.');
      logEvent(analytics, 'message_submit_empty');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    logEvent(analytics, 'page_change', { page });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(posts.length / postsPerPage);

  return (
    <div className="app">
      <header className="header">
        <img 
          src={logo} 
          alt="Logo" 
          className="logo" 
          onClick={() => logEvent(analytics, 'logo_click')} 
        />
      </header>
      <div className="content">
        <div className="list-container">
          <ul className="list">
            {currentPosts.map((post, index) => (
              <li 
                key={index} 
                className="list-item" 
                onClick={() => logEvent(analytics, 'post_view', { post_id: post.id })}
              >
                {post.content}

                {post.audio_link && (
                  <div>
                    <audio 
                      controls 
                      onPlay={() => logEvent(analytics, 'audio_play', { audio_link: post.audio_link })}
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        borderRadius: '0',
                        boxShadow: 'none',
                        marginRight: '20px',
                        border: '1px solid #ddd',
                      }}
                    >
                      <source src={post.audio_link} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <div className="message-form">
        <h3>Leave a Text</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              logEvent(analytics, 'message_typing', { char_count: e.target.value.length });
            }}
            placeholder="Type your message here..."
            className="message-input"
          />
          <button 
            type="submit" 
            className="submit-button" 
            onClick={() => logEvent(analytics, 'submit_button_click')}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default App;
