import React, { useEffect, useState } from 'react';
import { db } from './firebase';  // Assuming your Firebase is set up here
import { collection, getDocs } from 'firebase/firestore';
import './Profiles.css';

const Profiles = () => {
  const [socialProfiles, setSocialProfiles] = useState([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profilesCollection = collection(db, 'socialProfiles');  // Replace with your collection name
        const profilesSnapshot = await getDocs(profilesCollection);
        const profilesData = profilesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSocialProfiles(profilesData);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchProfiles();
  }, []);

  // Function to handle copying the link to clipboard
  const handleCopyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert(`Copied ${link} to clipboard!`);
    }).catch((error) => {
      console.error('Failed to copy:', error);
    });
  };

  return (
    <div className="profiles-container">
      {socialProfiles.map((profile) => (
        <div className="profile-card" key={profile.id}>
          {/* Clickable logo that copies the link */}
          <div 
            className="profile-logo" 
            style={{
              backgroundColor: profile.bgColor, 
              color: profile.textColor, 
              border: '1px solid #000', 
              borderRadius: '5px',
              padding: '10px',
              fontSize: '10px',
              cursor: 'pointer'
            }}
            onClick={() => handleCopyToClipboard(profile.link)}
          >
            {profile.name}
          </div>
          <a href={profile.link} target="_blank" rel="noopener noreferrer">
            {profile.link}
          </a>
        </div>
      ))}
    </div>
  );
};

export default Profiles;
